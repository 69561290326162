<template>
  <div class="banner-item" @click="openProduct">
    <div class="banner-preview" :style="{ 'padding-top': bannerPaddingTop }">
      <img :src="normalizeImg(image, 'thumb', 'FHD', 'HD')">
    </div>
    <slot name="title" />
  </div>
</template>
<script>
  import normalizeImg from '@/filters/normalizeImg';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'BannerProductItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
      link: {
        type: String,
        default: null,
      },
      index: {
        type: Number,
        required: true,
      },
      bannersSize: {
        type: String,
        default: 'large',
        require: true,
        validator(value) {
          return ['small', 'medium', 'large'].includes(value);
        },
      },
    },
    computed: {
      bannerPaddingTop() {
        if (this.bannersSize === 'small') {
          return '20%';
        }
        if (this.bannersSize === 'medium') {
          return '30%';
        }
        return '42%';
      },
      image() {
        return this.item?.banner;
      },
    },
    methods: {
      normalizeImg: normalizeImg,
      async openProduct() {
        const { head } = await amsClient.callAms(`/shoprzapi/product/${this.item.id}/?by=id`, { cache: false });

        const link = head.extern_shop || head.link || '';
        this.$stats.send('object_click', { position: this.index }, { item: head });

        if (link.includes('http://') || link.includes('https://')) {
          window.open(link, '_blank').focus();
        } else {
          this.$router.push(this.link);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>

</style>