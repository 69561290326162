var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoaded && !_vm.areasLive && _vm.isLiveRoute)?_c('search-error',{attrs:{"title":'Live',"header":'No Results',"problem-text":'Sorry, there are no live shows to display.',"solution":'Please check back later.'}}):_c('pv-scroll',{staticClass:"h-100 mt-3",class:{ 'no-scroll': _vm.noScroll, 'ugc-virtual-scroll': !_vm.isTabsAreas, },attrs:{"is-loading":_vm.isLoaded,"is-element":'app-content'},on:{"bottom":_vm.bottom}},[_c('transition-group',{staticClass:"h-100 d-flex flex-column",attrs:{"name":"stagged-area","appear":_vm.areasTransition,"appear-active-class":"animated fadeInUp","enter-active-class":"animated fadeInUp","leave-active-class":"animated fadeOutDown","tag":"div"},on:{"after-appear":_vm.onTransitionLeave,"enter":_vm.onTransitionEnter,"leave":_vm.onTransitionLeave}},_vm._l((_vm._areas),function(area,index){return _c('div',{key:area.id || index,class:_vm.isTabsAreas ? '' : area.itype,style:({ order: area.order }),on:{"mouseenter":function($event){return _vm.isTitle(area)}}},[(area.itype === 'custom_channel_profile')?_c('channel-profile',{key:"channelProfile",staticClass:"area-block custom_channel_profile",attrs:{"area":area,"badges":_vm.badges}}):(area.aclass === 'section_social')?_c('social-block',{staticClass:"area-block",attrs:{"area":area}}):(area.aclass === 'section_internal' && _vm.page !== 'article')?_c('segment-carousel',{attrs:{"area":area,"ordered":""}}):(
          area.itype === 'section_album' || area.itype === 'section_playlist'
        )?_c('audio-player',{staticClass:"area-block",attrs:{"album":area}}):(area.aclass === 'section_promo')?_c('promo',{staticClass:"area-block",attrs:{"area":area}}):(area.aclass === 'epg')?_c('live-epg',{staticClass:"area-block",attrs:{"area":area}}):(area.aclass === 'live_player' && _vm.page === 'live')?_c('live-player',{staticClass:"area-block",attrs:{"area":area}}):(
          area.aclass === 'video_flow' ||
            (area.aclass === 'video' && _vm.page === 'video')
        )?_c('media-player',{staticClass:"area-block",attrs:{"area":area,"extended":_vm.page === 'video'}}):(area.aclass === 'video' && _vm.page === 'home')?_c('vod',{staticClass:"area-block",attrs:{"area":area}}):_vm._e(),(
          [
            'item_mov_vod',
            'item_mov_vod_shoprz',
            'item_mov_episode' ].includes(area.itype)
        )?_c('media-player',{staticClass:"area-block",attrs:{"area":area,"shop-layouts":area.creator && area.creator.id ? area.creator : null,"is-one":true}}):(_vm.isSegmentCarousel(area.itype) || (area.itype === 'section_banner_shoprz' && area.dtype === 'promoted'))?_c('segment-carousel',{attrs:{"area":area,"ordered":"","reversed":area.itype === 'section_season',"border":index !== _vm._areas.length - 1 && area.itype !== 'section_season'}}):(_vm.isTabsSegment(area.itype))?_c('segment-tabs',{attrs:{"area":area}}):(_vm.isAutoSegmentCarousel(area.itype) && _vm.isShowAutoSegment(area))?_c('auto-segment-carousel',{attrs:{"area":area,"storefront-id":_vm.storefrontId}}):(
          ['category_head', 'category_series_head'].includes(area.itype)
        )?_c('category-title',{staticClass:"area-block",attrs:{"area":area}}):(area.itype === 'item_live')?_c('live-epg',{staticClass:"area-block",attrs:{"area":area}}):(area.itype === 'section_banner_shoprz' && area.dtype !== 'promoted')?_c('banner-carousel',{attrs:{"area":area}}):(area.itype === 'item_app')?_c('app-area',{staticClass:"area-block",attrs:{"area":area}}):(['section_link_directives'].includes(area.itype))?_c('directive-carousel',{staticClass:"area-block",attrs:{"area":area}}):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }