<template>
  <router-link
    :to="link"
    class="promo-card"
    draggable="false"
    ondragstart="return false;"
  >
    <div class="promo-card-img embed-responsive embed-responsive-1by1">
      <img
        draggable="false"
        class="embed-responsive-item"
        :src="item.poster| normalizeImg('thumb', 'SD', 'SD')"
        :alt="item.title"
        :title="item.title"
      >
      <!-- <v-lazy-image :intersection-options="{'rootMargin': '0px 270px' }" draggable="false" class="embed-responsive-item" :src="item.poster| normalizeImg('thumb', 'SD', 'SD')" :alt="item.title" :title="item.title" /> -->
    </div>

    <!-- <div class="promo-card-img" v-bg-image-js="{name: item.poster, type: 'thumb'}" :style="{'height': heightImg}"></div> -->
    <!-- v-bg-image-js="{name: item.poster, type: 'thumb'}" > -->

    <!--<div class="promo-title" v-ellipsisTextAndStripHTML="{content: item.title, resize: false}">-->
    <div class="promo-card-header">
      <trim-el
        class="promo-card-title d-none d-lg-block"
        :text="title"
        tag="h2"
      />
      <!-- <h2 class="promo-card-title d-none d-lg-block" :title="title" v-ellipsis-text="{content: title, resize: true}"> -->
      <!--{{ item.title | truncateOnWord(50) }}-->
      <!-- </h2> -->
      <!--<div class="promo-content" v-ellipsisTextAndStripHTML="{content: item.synopsis, resize: false}">-->
      <!-- <p
        class="promo-card-day"
        :title="promo_text"
      >
        {{ promo_text }}
      </p>
      <p class="promo-card-time fa-2x">
        <span>{{ sub_text }} </span>
        <span
          class="promo-icon fa fa-circle"
          :style="{color:color}"
        />
      </p> -->
    </div>
  </router-link>
</template>


<script>
  import device from 'src/service/device-service.js';
  import TrimEl from '../../components/common/trim-el';
  export default {
    name: 'PromoCard',
    components: {
      TrimEl,
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        heightImg: 'auto',
        minHeightComponent: 'auto',
      }
    },

    computed: {
      title() {
        return (this.item.title)? this.item.title : 'None';
      },
      poster() {
        return (this.item.poster)? this.item.poster : '';
      },
      link() {
        return (this.item.link)? this.item.link : '/';
      },
      promo_text() {
        return (this.item.promo_text)? this.item.promo_text : '-';
      },
      sub_text() {
        return (this.item.sub_text)? this.item.sub_text : '-';
      },
      color () {
        return (this.item.color)? this.item.color : '#1f55ff';
      },

    },
    mounted() {

    },
    beforeDestroy() {
    },
    methods: {
      setHeight1x1() {
        if (this.$el) {
          let ht = this.$el.offsetWidth ;
          this.heightImg =  ht + 'px';
          let staticHt = (device.isAnyMobile() || device.isMobileSize())? 67 : 128;
          this.minHeightComponent = (ht) ? ht + staticHt + 'px' : 'auto';
        } else {
          setTimeout(this.calcHeight16x9, 200);
        }
               
      },
      handleSize() {
        setTimeout(this.setHeight1x1, 300);
      },
    },
  }
</script>

<style lang="scss">
	@import './promo-card';
</style>
