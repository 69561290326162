<template>
  <div
    class="banner-item"
    @click="onClick"
  >
    <div class="banner-preview" :style="{ 'padding-top': bannerPaddingTop }">
      <img :src="normalizeImg(image, 'thumb', 'FHD', 'HD')">
<!--      <img :src="image">-->
    </div>
    <slot name="title" />
  </div>
</template>
<script>
  import * as dayjs from 'dayjs';
  import * as utcPlugin from 'dayjs/plugin/utc';
  dayjs.extend(utcPlugin);
  import normalizeImg from '@/filters/normalizeImg';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'BannerLinkItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
      link: {
        type: String,
        default: null,
      },
      index: {
        type: Number,
        required: true,
      },
      bannersSize: {
        type: String,
        default: 'large',
        require: true,
        validator(value) {
          return ['small', 'medium', 'large'].includes(value);
        },
      },
    },
    computed: {
      store() {
        return amsClient.get('conf.store');
      },
      storeName() {
        return this.store?.name || '';
      },
      // normalizedItemLink() {
      //   // department or channel // ToDO current front links incorrect for channels and departments
      //   if (this.item.itype === 'screen_shoprz_page') {
      //     return '/' + this.item?.link;
      //   }
      //   if (this.item.itype === 'item_epg_shop_event' && this.item?.link) {
      //     return `/s/${this.store.name.toLowerCase()}${this.item?.link}`
      //   }
      //   return this.item?.link;
      // },
      bannerPaddingTop() {
        if (this.bannersSize === 'small') {
          return '20%'
        }
        if (this.bannersSize === 'medium') {
          return '30%'
        }
        return '42%';
      },
      // link() {
      //   if (!this.item?.link) {
      //     return null;
      //   }
      //
      //   const splitUrl = this.item.link.split(/^(http|https):\/\/.{1,}\/portal/);
      //
      //   return splitUrl.length ? splitUrl[splitUrl.length - 1] : null;
      // },
      isRouterLink() {
        return this.$router.getRoutes()
          .filter(route => route.path !== '*')
          .some(route => route?.regex?.test(this.link));
      },
      image() {
        return this.item?.banner;
      },
    },
    methods: {
      normalizeImg: normalizeImg,
      async onClick() {
        let link = this.link;
        // ToDO bad approach, find a better way but with prevent redirect
        if (this.item.itype === 'item_epg_shop_event' && this.link) {
          const { head: storefront } = await amsClient.callAms(`/shoprzapi/storefront/${this.item.storefront}`);
          link = `/s/${storefront?.slug}${this.link}`;

          const { head: event } = await amsClient.callAms(link);

          if (event?.status === 'hidden' || event?.status === 'canceled') {
            return;
          }

          if (event?.status === 'waiting' && dayjs.utc(dayjs()).isAfter(dayjs.utc(event.end))) {
            return;
          }
        } else if (this.item.itype === 'screen_shoprz_page') {
          const link = this.link.replaceAll('/s/', '/screen/');
          const { head: screen } = await amsClient.callAms(link);
          if (screen?.storefront.deleted) {
            return;
          }
        } else if (this.item.itype === null && this.link) {
          let link = this.link.replace(/http:\/\/|https:\/\//g, '');
          const [storeUrl, ...path] = link.split('/');
          const storeName = storeUrl.split('.')[0]?.toLowerCase();
          if (storeName === this.storeName.toLowerCase()) {
            link = `/${path.join('/')}`;
            return this.$router.push(link);
          } else {
            return window.open(this.link, '_blank');
          }
        }
        // console.log('link', link);

        this.$router.push(link);
        // this.$stats.send('object_click', { position: this.index }, {item: this.item});
      },
    },
  };
</script>
<style lang="scss" scoped></style>