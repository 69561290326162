<template>
  <div v-if="info" class="info">
    <div class="info-header">
      <div class="info-header__logo">
        <img :src="logo">
      </div>
      <h2 class="info-header__name text-overflow">
        {{ name }}
      </h2>
      <span v-if="followers" class="info-header__views"> {{ followers }} followers </span>
    </div>
    <div class="info-body">
      <h3 class="info-title">
        {{ title }}
      </h3>
      <p class="info-description" v-html="description">

      </p>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'BannerVideoItemInfo',
    props: {
      info: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        // info: null,
      };
    },
    computed: {
      logo() {
        return this.info?.creator?.picture || this.info?.storefront?.picture || null;
      },
      name() {
        return this.info?.creator?.name || this.info?.storefront?.name || null;
      },
      followers() {
        return this.info?.creator.followers || this.info?.storefront?.followers || null;
      },
      title() {
        return this.info?.title || null;
      },
      description() {
        return this.info?.description || null;
      },
    },
    async mounted() {
    },

    methods: {},
  };
</script>
<style lang="scss" scoped>
  .info {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 16px 12px;
    //background-color: $header-background-color;
    color: #fff;
    background: linear-gradient(to right, transparent -40%, $header-background-color 50%);

    @media (max-width: 680px) {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      height: 70px;
      background: linear-gradient(to bottom, transparent 0%, $header-background-color 100%);
    }
  }


  .info-header {
    position: relative;
    min-height: 34px;
    margin-bottom: 22px;
    padding-left: 40px;

    &__logo {
      position: absolute;
      top: 2px;
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__name {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
    }

    &__views {
      display: block;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .info-body {
    //max-height: 170px;
    //overflow: hidden;

    .info-title {
      overflow-wrap: break-word;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
    }

    .info-description {
      font-size: 14px;
      line-height: 20px;

      ::v-deep p {
        margin-bottom: 0 !important;
      }
    }
  }


</style>
