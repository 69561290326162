<template>
  <div
    ref="videPlayerArea"
  >
    <slot/>
  </div>
</template>
<script>
  import {debounce} from 'lodash';
  import eventBus from "../../service/eventBus";

  export default {
    name: 'UgcVirtualScroll',
    props: {
      pageOffset: {
        type: Number,
        default: 50,
      },
      delayMs: {
        type: Number,
        default: 300,
      },
      isLoaded: {
        type: Boolean,
        default: true,
      },
      isElement: {
        type: null,
        default: null,
      },
    },
    data() {
      return {};
    },

    computed: {

      scrollDebounce() {
        return debounce(this.scroll, this.delayMs, {leading: true});
      },
      element() {
        // this.logger.log(`Search element ${this.isElement}`);
        if (this.isElement) {
          return document.getElementById(this.isElement) || null
        }
        // let el = document.getElementById(this.isElement);

        return null;
      },
    },

    mounted() {
      if (this.element) {
        this.element.addEventListener('scroll', this.scrollDebounce);
      } else {
        window.addEventListener('scroll', this.scrollDebounce);
      }
      window.addEventListener("resize", this.matchHeight);
      this.matchHeight();
    },
    beforeDestroy() {
      if (this.element) {
        // this.logger.log(`Remove scroll event from element`);
        this.element.removeEventListener('scroll', this.scrollDebounce);
      } else {
        // this.logger.log(`Remove scroll event from window`);
        window.removeEventListener('scroll', this.scrollDebounce);
      }
      window.removeEventListener("resize", this.matchHeight);
    },
    methods: {
      matchHeight() {
        let height = this.$refs.videPlayerArea.clientHeight;
        eventBus.$emit('videopChangeHeight', height);
      },
      scroll(evt) {
        if (!this.isLoaded || !this.isInViewport()) {
          // this.logger.log(`Reject scroll emit`);
          return;
        }
        // this.logger.log(`Accept scroll emit`);
        this.eventBottom();
      },

      isInViewport() {
        // current scroll position
        let position = this.element
          ? this.element.scrollHeight - this.element.scrollTop
          : document.documentElement.getBoundingClientRect().bottom;
        // height of viewport
        let fullHeight = this.element
          ? this.element.clientHeight
          : document.documentElement.clientHeight;

        // this.logger.log(`Position: ${position}, Full height: ${fullHeight}`);

        return position <= fullHeight + fullHeight * this.pageOffset;
      },

      eventBottom() {
        // this.logger.log(`Emit bootom event`);
        this.$emit('bottom');
      },
    },
  };
</script>

<style lang="scss">
  .ugc-virtual-scroll {
    //min-height: calc(100vh + 100px);
    // overflow: hidden;
    // overflow-y: auto;
    // height: calc(100%);
    // margin: 0;
  }
</style>

