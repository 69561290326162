<template>
  <search-error
    v-if="!isLoaded && !areasLive && isLiveRoute"
    :title="'Live'"
    :header="'No Results'"
    :problem-text="'Sorry, there are no live shows to display.'"
    :solution="'Please check back later.'"
  />
  <pv-scroll
    v-else
    :is-loading="isLoaded"
    :is-element="'app-content'"
    :class="{ 'no-scroll': noScroll, 'ugc-virtual-scroll': !isTabsAreas, }"
    class="h-100 mt-3"
    @bottom="bottom"
  >
    <transition-group
      name="stagged-area"
      :appear="areasTransition"
      appear-active-class="animated fadeInUp"
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
      tag="div"
      class="h-100 d-flex flex-column"
      @after-appear="onTransitionLeave"
      @enter="onTransitionEnter"
      @leave="onTransitionLeave"
    >
      <!--      <channel-event-->
      <!--        v-if="currentLive"-->
      <!--        key="currentLive"-->
      <!--        class="area-block custom_channel_event"-->
      <!--        :area="currentLive"-->
      <!--      />-->

      <div
        v-for="(area, index) in _areas"
        :key="area.id || index"
        :style="{ order: area.order }"
        :class="isTabsAreas ? '' : area.itype"
        @mouseenter="isTitle(area)"
      >
        <channel-profile
          v-if="area.itype === 'custom_channel_profile'"
          key="channelProfile"
          class="area-block custom_channel_profile"
          :area="area"
          :badges="badges"
        />
        <social-block
          v-else-if="area.aclass === 'section_social'"
          class="area-block"
          :area="area"
        />

        <segment-carousel
          v-else-if="area.aclass === 'section_internal' && page !== 'article'"
          :area="area"
          ordered
        />

        <audio-player
          v-else-if="
            area.itype === 'section_album' || area.itype === 'section_playlist'
          "
          class="area-block"
          :album="area"
        />

        <promo
          v-else-if="area.aclass === 'section_promo'"
          class="area-block"
          :area="area"
        />

        <live-epg
          v-else-if="area.aclass === 'epg'"
          class="area-block"
          :area="area"
        />

        <live-player
          v-else-if="area.aclass === 'live_player' && page === 'live'"
          class="area-block"
          :area="area"
        />

        <media-player
          v-else-if="
            area.aclass === 'video_flow' ||
              (area.aclass === 'video' && page === 'video')
          "
          class="area-block"
          :area="area"
          :extended="page === 'video'"
        />
        <vod
          v-else-if="area.aclass === 'video' && page === 'home'"
          class="area-block"
          :area="area"
        />

        <media-player
          v-if="
            [
              'item_mov_vod',
              'item_mov_vod_shoprz',
              'item_mov_episode',
            ].includes(area.itype)
          "
          class="area-block"
          :area="area"
          :shop-layouts="area.creator && area.creator.id ? area.creator : null"
          :is-one="true"
        />

        <segment-carousel
          v-else-if="isSegmentCarousel(area.itype) || (area.itype === 'section_banner_shoprz' && area.dtype === 'promoted')"
          :area="area"
          ordered
          :reversed="area.itype === 'section_season'"
          :border="
            index !== _areas.length - 1 && area.itype !== 'section_season'
          "
        />

        <segment-tabs v-else-if="isTabsSegment(area.itype)" :area="area"/>

        <auto-segment-carousel
          v-else-if="isAutoSegmentCarousel(area.itype) && isShowAutoSegment(area)"
          :area="area"
          :storefront-id="storefrontId"
        />

        <category-title
          v-else-if="
            ['category_head', 'category_series_head'].includes(area.itype)
          "
          class="area-block"
          :area="area"
        />

        <live-epg
          v-else-if="area.itype === 'item_live'"
          class="area-block"
          :area="area"
        />

        <banner-carousel
          v-else-if="area.itype === 'section_banner_shoprz' && area.dtype !== 'promoted'"
          :area="area"
        />

        <app-area
          v-else-if="area.itype === 'item_app'"
          class="area-block"
          :area="area"
        />

        <directive-carousel
          v-else-if="['section_link_directives'].includes(area.itype)"
          class="area-block"
          :area="area"
        />
      </div>
    </transition-group>
  </pv-scroll>
</template>

<script>
  import Config from '@/service/config';
  import SocialBlock from 'src/components/social-block/social-block';
  import InternalCarousel from 'src/components/pro-carousel/internal-procarousel';
  import DirectiveCarousel from 'src/components/pro-carousel/directive-carousel';
  import ProCarousel from 'src/components/pro-carousel/pro-carousel';
  import Promo from 'src/components/promo/promo';
  import Vod from 'src/components/player/main/vod';
  import MediaPlayer from 'src/components/player/media/media-player';
  import LiveEpg from 'src/components/live-epg/live-epg';
  import ChannelProfile from 'src/components/channel-profile/channel-profile';
  import ChannelEvent from 'src/components/channel-profile/channel-event';
  import BlockChanels from '../block-chanels/block-chanels';
  import Backdrop from '../backdrop/backdrop';
  import AudioPlayer from 'src/components/player/audio-player/audio-player';
  import VideoPlayer from 'src/components/video-player.vue';
  import AppArea from 'src/components/area-app.vue';
  import CategoryTitle from 'src/components/category-title/category-title';
  import PvScroll from 'src/components/pv-scroll/scroll';
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import SegmentCarousel from 'src/components/segment-carousel/segment-carousel';
  import SegmentTabs from 'src/components/segment-tabs/segment-tabs.vue';
  import AutoSegmentCarousel from 'src/components/segment-carousel/auto-segment-carousel';
  import BannerCarousel from 'src/components/banner-carousel/banner-carousel';
  import SearchError from '../searchError/searchError.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Areas',
    components: {
      SegmentCarousel,
      SegmentTabs,
      AutoSegmentCarousel,
      BlockChanels,
      MediaPlayer,
      SocialBlock,
      Promo,
      AudioPlayer,
      InternalCarousel,
      ProCarousel,
      Vod,
      LiveEpg,
      ChannelProfile,
      ChannelEvent,
      Backdrop,
      VideoPlayer,
      AppArea,
      CategoryTitle,
      DirectiveCarousel,
      PvScroll,
      BannerCarousel,
      SearchError,
    },
    mixins: [AuthVariableMixin],
    props: {
      page: {
        type: String,
        default: null,
        validator: function(page) {
          let types = [
            'video',
            'home',
            'article',
            'show',
            'channel',
            'live',
            'search',
            'shows-az',
            'dashboard',
            'section',
            'category',
          ];
          return types.indexOf(page) !== -1;
        },
      },
      areas: {
        type: Array,
        default: () => [],
      },
      isTabsAreas: {
        type: Boolean,
        default: false,
      },
      scroll: {
        type: Boolean,
        default: false,
      },
      profile: {
        type: Object,
        default: null,
      },
      currentLive: {
        type: Object,
        default: undefined,
      },
      badges: {
        type: Object,
        default: null,
      },
      areasTransition: {
        type: Boolean,
        default: true,
      },
      storefrontId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        visibleAreas: [],
        index: 0,
        isLoaded: false,
        COUNT_ITEMS: 25,
        widthBlock: 0,
        sizeWidth: null,
      };
    },
    computed: {
      ...mapGetters({
        areasLive: 'dataAreasLive/areasLive',
      }),

      isSegmentCarousel() {
        return (itype) => {
          return [
            'area_grid',
            'section',
            'section_filters',
            'section_shoprz',
            'section_filters_shoprz',
            'section_link_shoprz',
            'section_shopitem',
            'section_shopitem_shoprz',
            'category',
            'category_shoprz',
            'section_channels',
            'category_series',
            // 'section_link',
            'section_link_directives',
            'section_season',
          ].includes(itype);
        };
      },
      isAutoSegmentCarousel() {
        return (itype) => {
          return [
            'section_filter',
            'section_link',
          ].includes(itype);
        };
      },
      isTabsSegment() {
        return (itype) => {
          return [
            'category_tabs',
          ].includes(itype);
        };
      },
      _areas() {
        if (_isPreviewMode()) {
          return this.visibleAreas.filter((area) => {
            return ['custom_channel_profile'].indexOf(area.itype) !== -1;
          });
        }

        let _areas = this.visibleAreas;

        const otherProducts = _areas.find(
          (segment) => segment.title === 'Other Products',
        );

        if (otherProducts) {
          _areas = _areas.filter((a) => a.title !== 'Other Products');
          _areas.push(this.generateOtherProductsSegment(otherProducts));
        }

        return _areas;
      },
      isActiveScroll() {
        return this.isActiveFeature('use-scroll-area');
      },
      noScroll() {
        return (!this.scroll && this.areas.length < 2) || !this.scroll;
      },
      isLiveRoute() {
        return this.$route.path === '/screen/live/';
      },
    },
    watch: {
      areas: function() {
        this.index = 0;
        this.isLoaded = false;
        this.visibleAreas = [];
        if (this.isActiveScroll) {
          this.addItemsByScroll();
        } else {
          this.pushNextItem();
        }
      },
    },
    created() {
      this.index = 0;

      if (this.isActiveScroll) {
        this.addItemsByScroll();
      } else {
        this.pushNextItem();
      }
    },
    methods: {
      isShowAutoSegment(area) {
        // return true; // todo remove
        return !!area.is_show
      },
      isTitle(itype) {
        this.$bus.$emit('isTitle', itype.title);
      },
      onTransitionEnter() {
        this.$root.inTransition = true;
      },
      onTransitionLeave() {
        this.$root.inTransition = false;
      },
      bottom() {
        if (this.isActiveScroll) {
          this.addItemsByScroll();
        }
      },
      addItemsByScroll() {
        if (this.areas.length > 0 && !this.isLoaded) {
          let counter = Math.floor(this.index / this.COUNT_ITEMS) + 1;
          this.index = this.COUNT_ITEMS * counter;
          let pushed = this.areas.slice(
            this.index - this.COUNT_ITEMS,
            this.index,
          );

          pushed.forEach((element) => {
            this.visibleAreas.push(element);
          });

          if (this.index >= this.areas.length) {
            this.isLoaded = true;
          }
        }
      },
      pushNextItem() {
        if (this.areas.length > 0) {
          this.visibleAreas.push(this.areas[this.index]);
          this.index++;
          if (this.index < this.areas.length) {
            if (this.index < 4) {
              this.$nextTick(this.pushNextItem);
            } else {
              this.pushNextItem();
            }
          }
        }
      },
      generateOtherProductsSegment(segment) {
        return {
          poster: null,
          backdrop: null,
          title: segment.title,
          link: segment.link,
          id: null,
          itype: 'section_shopitem_shoprz',
          n_col: 3,
          aspect_ratio: '1x1',
          dtype: 'carousel',
          item_image_shape: 'vertical',
          item_title_position: 'bottom',
          seo_metadata: {
            title: segment.title,
            page_link: null,
          },
          page: null,
        };
      },
    },
  };
</script>

<style lang="scss">
  @import './areas';

  .no-scroll {
    min-height: auto !important;
  }

  .page-container.with-player.shop-player-not-active .tabs-segment .ugc-virtual-scroll {
    margin-top: 1rem !important;
  }

  .tabs-segment {
    .ugc-virtual-scroll.no-scroll {

      .segment-header {
        margin-top: 0;
      }
    }
  }
</style>
