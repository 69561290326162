<template>
  <pro-carousel
    v-if="!loading"
    class="internal-section-component"
    :area="area"
    :buh-context="stockType"
    :items-prop="items"
  />
</template>

<script>
  import ProCarousel from './pro-carousel';
  import { amsClient } from 'src/service/ams';
  import Auth from 'src/service/authService';

  export default {
    name: 'InternalSection',
    components: {
      ProCarousel,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
        items: [],
      };
    },
    computed: {
      stockType() {
        if(this.tag === 'watchlist'){
          return 'recent'
        }else if(this.tag === 'favorite') {
          return 'favorites'
        }else
          return this.tag

      },
      tag() {
        return this.area && this.area.directives && this.area.directives.tag ? this.area.directives.tag : null;
      },
    },
    created() {
      this.getData();
      this.$bus.$on('sync', this.onSync);
    },
    beforeDestroy() {

      this.$bus.$off('sync', this.onSync);
    },
    methods: {
      getData() {
        this.loading = true;
        if (Auth.isAuth() && !Auth.isGuest() && ['recent', 'favorites','watch_later'].indexOf(this.stockType) !== -1) {

          let call;
          if (this.stockType === 'favorites') {
            call = amsClient.stock.getFavorites(false, 0, 50);
          } else if (this.stockType === 'recent') {
            call = amsClient.stock.getRecent(false, 0, 50);
          } else if (this.stockType === 'watch_later') {
            call = amsClient.stock.getWatchLater(false, 0, 50);
          }
          call.then((items) => {
            this.items = items;
            this.loading = false;
          }).catch((error) => {
            // console.log(error);
            this.items = [];
            this.loading = false;
          });
          // this.$cm.getMediaByTagName(this.tag, res => {
          //   let tempList = {};
          //   if (!res) {
          //     return;
          //   }
          //   // TODO: need count from link
          //   let tmp = this.link.match(/count=([^&\s]*)/);
          //   let count = tmp.length > 0 && tmp[1] ? tmp[1] : 100;
          //   let iids = res.slice(0, count)
          //     .map(el => el.mediaId);
          //   if (iids.length) {
          //     let url = this.link.replace(`{{iid}}`, iids.join(','));
          //     amsClient.callAms(url)
          //       .then(data => {
          //         let items = data.items;
          //         items.forEach((item, index) => {
          //           let pos = iids.indexOf(item.iid || item.id);
          //           if (pos !== -1) {
          //             item.poster = item.poster || item.backdrop;
          //             item.synopsis = item.synopsis || item.description;
          //             tempList[pos] = item;
          //           }
          //         });
          //         // NOTE: don`t change to assign : the order will be correct, but can be not set items
          //         this.items = Object.values(tempList);
          //         this.loading = false;
          //       });
          //   }
          // });
        } else {
          this.$nextTick(()=>{
            this.items = [];
            this.loading = false;
          })
        }
      },
      //
      onSync() {
        // setTimeout(() => {
        this.getData();
        // }, 300);
      },
    },
  };
</script>

<style lang="scss">
</style>
