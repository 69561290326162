<template>
  <section
    class="home-container"
    :class="{'shop-player-not-active':!shopPlayerFirstClick, 'with-player': isShopPlayerEnabled}"
  >
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="!isAvailable" />

    <shop-player
      v-if="isAvailable && isShopPlayerEnabled"
      ref="shopPlayer"
      :class="shopPlayerTagClasses"
      :items="shopPlayerItems"
      :is-loading="shopPlayerItemsLoading"
      :scroll-navigate="false"
      :active-content-type="shopPlayerActiveType"
      :cropped-mode="!shopPlayerFirstClick"
      :picture-in-picture="!!floatItem"
      :shop-controls="shopPlayerControls"
      :active-item="shopPlayerActiveItem"
      :items-products="itemsProducts"
      :items-stats="itemsStats"
      :items-overlay-state="itemsOverlaysState"
      :items-channel-follow-state="itemsChannelsFollowState"
      :chat-config="chatConfig"
      :use-high-initial-bitrate="true"
      :rewind="true"
      :full-page-mode="shopPlayerFullPageMode"
      :autoplay="!floatItem && !popupItem"
      :loop="shopPlayerActiveType === 'vod'"
      :logo-link="logoLink || require('src/assets/logo/logo-vimmi-white.svg')"
      :background="'purple'"
      :viewers-limit-reached="viewersLimitReached"
      :get-playback-url="getPlaybackUrl"
      :can-play="canPlay"
      @playback-state="shopPlayerPlaybackStateUpdated($event)"
      @toggle-type="shopPlayerAreaClick(); shopPlayerToggleType($event, true)"
      @area-click="shopPlayerAreaClick"
      @play-btn-click="shopPlayClick"
      @fs-state="shopPlayerFullScreenChanged"
      @active-item="setShopPlayerActiveItem($event)"
      @open-pip="openFloatPlayer($event)"
      @toggle-follow="toggleFollow($event)"
      @block-scroll="blockPageScroll($event)"
      @unblock-scroll="unBlockPageScroll($event)"
      @chat-user-updated="chatUserUpdated($event)"
      @product-click="onProductClick($event)"
      @close-product="closeProductsDetailsPopup($event)"
      @send-reaction="sendReaction($event)"
      @share="openSharePopup($event)"
      @need-login="showLoginPopup($event)"
      @player:stats="shopPlayerStats($event, 'main')"
    >
      <template #products-details-popup>
        <product-details-popup-component
          v-if="productPopupDetails"
          :product="productPopupDetails"
          @close="closeProductsDetailsPopup"
        />
      </template>
    </shop-player>
    <areas
      v-if="isAvailable"
      :areas="areas"
      :page="'home'"
      :scroll="false"
    />
    <b-modal
      ref="error_modal"
      :title="modalTitle"
      hide-footer
    >
      <span v-t="'MODAL.PAGE.CONTENT'" />
    </b-modal>
  </section>
</template>

<script>
  import Areas from '@/components/areas/areas';
  import Loader from '@/components/loader/loader';
  import JsonLD from '@/service/jsonld';
  import Config from '@/service/config';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import { ScreenReadyMixin, AuthVariableMixin } from '@/plugins/mixin';
  import { getMetaIos } from '@/utils/generateDeepLink';
  import { amsClient } from '@/service/ams';

  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import rtm from '@/service/realTimeMessages';
  import { debounce } from 'lodash';
  import device from '@/service/device-service';
  import RecommendationsApi from '@/shared/api/recommendations/';
  import { mapState } from 'vuex';
  import smoothScroll from '@/shared/helpers/smooth-scroll';
  import FloatPlayerMixin from '@/shared/mixins/float-player.mixin';
  import ShopPlayerMixin from '@/shared/mixins/shop-player.mixin';
  import ShoprzStore from '@/store/shoprz';

  import ProductDetailsPopupComponent
    from '@/shared/components/shop-player/product-details-popup.component.vue';
  import ShopPlayerService from '@/shared/services/shop-player.service';

  export default {
    name: 'HomeContainer',
    components: {
      ProductDetailsPopupComponent,
      // Should be dynamic import for recursive component use in th Shop Tabs
      Areas: () => import('@/components/areas/areas'),
      Loader,
    },
    mixins: [AuthVariableMixin, ScreenReadyMixin, FloatPlayerMixin, ShopPlayerMixin],
    props: [],
    data() {
      return {
        shopPlayerItemsLoading: true,
        shopPlayerVodItems: [],
        shopPlayerLiveItems: [],
        shopPlayerActiveType: null,
        shopPlayerFirstClick: !!device.isAnyMobile(),
        shopPlayerActiveItem: null,
        // shopPlayerPlayStateBeforePopup: null,
        shopPlayerPlaybackState: {},
        shopPlayerFullPageMode: true,
        isScrollFloatPlayerEnable: false,
        areas: [],
        head: {},
        ads: {},
        loading: true,
        pageAds: [],
        prevPage: null,
        currentPage: null,
        rtmSubscription: null,
        reloadComponent: true,
        metadata: {},
        logoLink: '',
        tabs: [],
        activeTab: null,
      };
    },
    computed: {
      ...mapState('popupPlayer', {
        vodPopupItem: 'item',
        vodPopupPlaybackState: 'playbackState',
      }),
      shopPlayerItems() {
        const items = !this.shopPlayerActiveType ? [] : this.shopPlayerActiveType === 'vod' ? this.shopPlayerVodItems : this.shopPlayerLiveItems;

        items.forEach((element) => {
          this.$set(
            this.itemsChannelsFollowState,
            element.id,
            ShoprzStore.isChannelFollowed(element?.creator?.id || element?.storefront?.id),
          );
        });

        return items;
      },
      isAvailable() {
        return !this.loading; // && this.context && this.context.conf;
      },
      isShopPlayerEnabled() {
        return this.head?.player_enabled === undefined || this.head?.player_enabled; // by default should be shown
      },
      modalTitle() {
        return this.$t('MODAL.PAGE.TITLE');
      },
      shopPlayerControls() {
        return {
          pip: !this.floatItem,
          volume: !this.floatItem,
          fullscreen: !this.floatItem,
          follow: true,
          liveToggler: !!this.shopPlayerLiveItems.length && !this.floatItem,
          vodToggler: !this.floatItem,
          upDownToggler: !this.floatItem,
          chat: !!this.shopPlayerFirstClick,
          share: true,
          progressBar: true,
          reactions: true,
        };
      },
    },
    watch: {
      isAvailable(val) {
        if (val) {
          this.setScreenReady(this.head, this.screenRequestTime);
        }
      },

      vodPopupPlaybackState(val, prevVal) {
        if (this.$refs.shopPlayer && this.shopPlayerActiveItem) {
          if (!val.paused && !this.shopPlayerPlaybackState.paused) {
            // this.shopPlayerPlayStateBeforePopup = !this.shopPlayerPlaybackState.paused;
            this.$refs.shopPlayer.pause(null, false);
          }
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.loading = true;
        if (!to.query.onlyregister || !to.query.onlyrestore) {
          vm.cleanPlayerState();
          rtm.off(vm.rtmSubscription);
          vm.get2ndHome();
        }
      });
    },
    beforeRouteUpdate(to, from, next) {
      const prev = to && to.params && to.params.name ? to.params.name : null;
      const curr = from && from.params && from.params.name ? from.params.name : null;

      if (curr && prev && prev !== curr) {
        this.currentPage = null;
        this.cleanPlayerState();
        rtm.off(this.rtmSubscription);
        JSON.pop();
        this.loading = true;
        this.get2ndHome();
      }
      next();
    },
    created() {
      document.addEventListener('scroll', this.onPageScroll);

      Config.loaded(() => {
        this.logoLink = amsClient.get('conf.store.logo');
        // this.logo = 'https://dev-vod-cdn.shoprz.live/posters/dev-shoprz.40751.cust.original.jpg'
      });
    },
    mounted() {
      this.rtmSubscription = rtm.on(['started', 'on_air_now', 'finished'], debounce(async (data) => {
        if (data.op === 'finished') {
          setTimeout(() => {
            if (this.shopPlayerActiveType === 'live') {
              const index = this.shopPlayerLiveItems.findIndex((liveItem) => liveItem.id === data.room);
              const finishedItem = this.shopPlayerLiveItems[index];
              this.shopPlayerLiveItems = this.shopPlayerLiveItems.filter((liveItem) => liveItem.id !== data.room);
              if (!this.shopPlayerLiveItems.length) {
                this.shopPlayerToggleType('vod', true);
              } else if (finishedItem?.id === this.shopPlayerActiveItem?.id) {
                if (this.floatItem?.id === this.shopPlayerActiveItem.id) {
                  /** todo: play next/prev live in float player or just hide it */
                  this.hideFloatPlayer();
                }

                this.loadFullItem(this.shopPlayerLiveItems[index] || this.shopPlayerLiveItems[index - 1]).then((item) => {
                  this.shopPlayerActiveItem = item;
                });
              }
            }
          }, 8000); // todo: timeout
        } else if ((data.op === 'started' || data.op === 'on_air_now') && !this.shopPlayerItemsLoading) {
          const latestItems = await this.loadShopPlayerItems('live');
          if (!this.shopPlayerLiveItems.length) {
            this.shopPlayerLiveItems = latestItems;
          } else {
            const newItem = latestItems.find((item) => item.id === data.room);
            const existItem = this.shopPlayerLiveItems.find((item) => item.id === data.room);
            if (newItem && !existItem) {
              this.shopPlayerLiveItems.push(newItem);
            }
          }
        }
      }, 1000));
      this.$bus.$emit('handleSpecificClass', this.shopPlayerSpecialClasses);
      this.$bus.$emit('handleHeaderSpecificClass', 'shop-player-header');
      this.$bus.$on('logout', this.get2ndHome);
      this.$bus.$on('amsLogged', this.get2ndHome);
    },
    beforeDestroy() {
      document.removeEventListener('scroll', this.onPageScroll);
      this.$bus.$emit('handleSpecificClass', '');
      this.$bus.$emit('handleHeaderSpecificClass', '');
      this.cleanPlayerState();
      rtm.off(this.rtmSubscription);
      JsonLD.pop();
    },
    destroyed() {
    },
    methods: {
      /**
       * @method
       * @desc used in the float-player-mixin
       * @return {Vue & ShopPlayerContainer}
       * */
      getShopPlayerRef() {
        return this.$refs.shopPlayer;
      },
      /**
       * @method
       * @desc used in the float-player-mixin
       * @return {Object}
       * */
      getActiveItem() {
        return this.shopPlayerActiveItem;
      },
      onPageScroll() {
        if (document.scrollingElement?.scrollTop) {
          this.$bus.$emit('handleHeaderSpecificClass', '');
        } else {
          this.$bus.$emit('handleHeaderSpecificClass', 'shop-player-header');
        }
      },
      onChangedUserState(event) {
        if (this.head) {
          const t = this.areas;
          this.areas = [];
          this.$nextTick(() => {
            this.areas = t;
          });
        }
      },
      get2ndHome() {
        this.areas = [];
        this.loading = true;

        amsClient.callAms(Config.get('amsEntryPoint')).then((data) => {
          if (data.items.length) {
            this.getHomeData(data.items[0].link);
          } else {
            this.$router.replace({
              path: '/error',
            });
          }
        });
      },
      getHomeData(url = '/screen/home_web/') {
        if (_isPreviewMode()) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}preview=1`;
        }
        this.screenRequestTime = this.setScreenRequest({
          link: url,
          itype: 'screen',
        });

        amsClient
          .callAms(url)
          .then((data) => {
            this.areas = data.items;
            this.ads = {};

            data.head.cached = !!data.cached;
            this.head = data.head;
            if (this.isShopPlayerEnabled) {
              this.initShopPlayer();
            }
            this.$stats.send('page_opened', {
              page_type: 'home',
            }, { item: this.head });

            this.setCurrentScreen(this.head);
            this.setScreenResponse(this.head);
            this.loading = false;
            this.fillHeader();
            this.addSchemaOrg();
            // this.$stats.send('PageView', {}, { only: ['fbq'] });

            this.pageAds = this.head && this.head.ads && this.head.ads.placements
              ? this.head.ads.placements[0]
              : {};
          })
          .catch((err) => {
            this.$plError.setError(err, 'network');
            this.$router.replace({ path: '/error' });
          });
      },
      async initShopPlayer() {
        this.shopPlayerItemsLoading = true;
        /** if returned from float player */
        let floatItem = null;
        let floatItemId = null;
        let floatItemLive = null;

        if (this.floatOptions.type !== 'vod-popup' && this.floatOptions.openerPage === this.$route.fullPath) {
          // if (this.floatOptions?.returned) {
          floatItem = this.floatOptions.item;
          floatItemId = this.floatOptions.itemId;
          floatItemLive = this.floatOptions.isLive;
          this.setFloatItem(null);
          this.setFloatOptions(null);
          // }
        }

        this.shopPlayerLiveItems = await this.loadShopPlayerItems('live');
        this.shopPlayerVodItems = await this.loadShopPlayerItems('vod');

        let activeFloatItem;
        if (floatItemId) {
          if (floatItemLive) {
            activeFloatItem = this.shopPlayerLiveItems.find((item) => floatItemId === item.id);
            // if the list refreshed
            if (!activeFloatItem && floatItem) {
              this.shopPlayerLiveItems.unshift(floatItem);
              activeFloatItem = floatItem;
            }
          } else {
            activeFloatItem = this.shopPlayerVodItems.find((item) => floatItemId === item.id);
            // if the list refreshed
            if (!activeFloatItem && floatItem) {
              this.shopPlayerVodItems.unshift(floatItem);
              activeFloatItem = floatItem;
            }
          }
        }

        if (activeFloatItem) {
          this.shopPlayerActiveType = floatItemLive ? 'live' : 'vod';
          this.shopPlayerActiveItem = await this.loadFullItem(activeFloatItem);
          if (floatItemLive) {
            const index = this.shopPlayerLiveItems.findIndex((item) => activeFloatItem.id === item.id);
            this.$set(this.shopPlayerLiveItems, index, { ...this.shopPlayerActiveItem });
          } else {
            const index = this.shopPlayerVodItems.findIndex((item) => activeFloatItem.id === item.id);
            this.$set(this.shopPlayerVodItems, index, { ...this.shopPlayerActiveItem });
          }
          this.applyFloatStateAfterInitShopPlayer(floatItemId);
        } else if (this.shopPlayerLiveItems.length) {
          this.shopPlayerActiveItem = await this.loadFullItem(this.shopPlayerLiveItems[0]);
          this.$set(this.shopPlayerLiveItems, 0, { ...this.shopPlayerActiveItem });
          this.shopPlayerActiveType = 'live';
        } else if (this.shopPlayerVodItems.length) {
          this.shopPlayerActiveItem = await this.loadFullItem(this.shopPlayerVodItems[0]);
          this.$set(this.shopPlayerVodItems, 0, { ...this.shopPlayerActiveItem });
          this.shopPlayerActiveType = 'vod';
        }

        this.shopPlayerItemsLoading = false;
        if (!this.vodPopupItem) {
          this.$nextTick(() => {
            smoothScroll(document.documentElement, {
              top: 0,
              duration: 100,
            });
          });
        }
      },
      async loadShopPlayerItems(type) {
        let items = [];
        try {
          if (type === 'live') {
            items = await RecommendationsApi.getLIVERecommendations({ page: 1, pageSize: 20 });// amsClient.callAms('/on_air/', { cache: false });
          } else {
            items = await RecommendationsApi.getVODRecommendations({ page: 1, pageSize: 20 });// await amsClient.callAms(this.shopPlayerVodSection.link);
          }
        } catch (e) {
          console.warn(e);
        }
        await this.onPlayTemplateReady();
        return items.map((item) => {
          const playbackUrl = ShopPlayerService.generatePlaybackUrl(item, this.getPlayTemplate);
          if (playbackUrl) {
            item.playback_url = playbackUrl;
          }
          return item;
        });
      },
      async shopPlayerToggleType(type = 'vod', setFirstActive = false) {
        this.shopPlayerItemsLoading = true;
        let items = [];
        try {
          items = await this.loadShopPlayerItems(type);
        } catch (e) {
          console.error(e);
        }
        if (type === 'vod') {
          this.shopPlayerVodItems = items;
        } else if (type === 'live') {
          this.shopPlayerLiveItems = items;
        }
        this.shopPlayerActiveType = type;
        this.shopPlayerItemsLoading = false;
        if (setFirstActive && items.length) {
          this.shopPlayerActiveItem = await this.loadFullItem(items[0]);
        }
        return items;
      },

      async shopPlayClick($event) {
        this.shopPlayerAreaClick({ ...$event, play: true });
      },
      async shopPlayerAreaClick($event) {
        if (this.floatItem && $event?.play) {
          if (this.floatItem.id === this.shopPlayerActiveItem?.id && this.floatOptions.type !== 'vod-popup') {
            this.applyFloatPlayerStateToMain(null, true);
          }
          this.hideFloatPlayer();
        }
        this.shopPlayerFirstClick = true;
        this.$bus.$emit('handleSpecificClass', this.shopPlayerSpecialClasses);
      },
      shopPlayerPlaybackStateUpdated({ itemId, state }) {
        if (itemId === this.shopPlayerActiveItem?.id) {
          this.shopPlayerPlaybackState = state;
        }
      },
      async setShopPlayerActiveItem(item) {
        this.loadingItemId = item.id;

        const fullItem = await this.loadFullItem(item);
        if (fullItem !== item) {
          if (this.shopPlayerActiveType === 'vod') {
            const index = this.shopPlayerVodItems.findIndex((_item) => _item.id === item.id);
            this.$set(this.shopPlayerVodItems, index, fullItem);
          }
          if (this.shopPlayerActiveType === 'live') {
            const index = this.shopPlayerLiveItems.findIndex((_item) => _item.id === item.id);
            this.$set(this.shopPlayerLiveItems, index, fullItem);
          }
        }

        if (this.loadingItemId === fullItem.id) {
          this.shopPlayerActiveItem = fullItem;
        }
      },
      fillHeader() {
        this.metadata = normaliseMetaTags(this.head);
        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: 200 },
          'meta[name="apple-itunes-app"]': { content: getMetaIos(this.head) },
        });
      },

      showErrorModal(err, code) {
        if (err) {
          this.$refs.error_modal.show();
          code = 301;

          memoryStore.set('close_backdrop', true);
        }
        const res = code ? { content: `${code}` } : { content: '200' };
        this.metadata.head = { ...this.metadata.head, 'meta[name="prerender-status-code"]': res };
      },
      addSchemaOrg() {
        const data = {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: location.origin,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${location.origin}/search?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        };
        JsonLD.push(data);
        JsonLD.update();
      },
      cleanPlayerState() {
        this.shopPlayerItemsLoading = true;
        this.shopPlayerVodItems = [];
        this.shopPlayerLiveItems = [];
        this.shopPlayerActiveType = null;
        this.shopPlayerActiveItem = null;
        // this.shopPlayerPlayStateBeforePopup = null;
        this.shopPlayerPlaybackState = {};
        this.itemsChannelsFollowState = {};
      },
    },
  };
</script>

<style lang="scss">
  @import "shop-player-v2/dist/style";

  .home-container.with-player {
    @import "src/style/shop-player-extra";
  }

  @import './Home';
</style>