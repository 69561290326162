<template>
  <div class="banner-item" @click="openVod">
    <div class="banner-preview" :style="{ 'padding-top': bannerPaddingTop }">
      <video
        :ref="`video_${item.b_id}`"
        playsinline
        muted
        preload="none"
        :class="{
          'is-vertical': isVertical,
          'is-small-banner': bannersSize === 'small',
          'is-large-banner': bannersSize === 'large',
          'is-medium-banner': bannersSize === 'medium',
          'with-banner-info': showInfoBlock
        }"
        :style="{ 'background-image': 'url(' + normalizeImg(image, 'thumb', 'FHD', 'HD') + ')' }"
      />
<!--      <lock-icon-component v-if="isShowLockIcon()"></lock-icon-component>-->
      <banner-video-item-info v-if="showInfoBlock" :style="{ width: infoBlockWidth }" :info="bannerInfo" />
    </div>
    <slot name="title" />
  </div>
</template>
<script>
  import * as dayjs from 'dayjs';
  import * as utcPlugin from 'dayjs/plugin/utc';

  dayjs.extend(utcPlugin);
  import { mapActions, mapGetters } from 'vuex';
  import BannerVideoItemInfo from './banner-video-item-info/banner-video-item-info';
  import normalizeImg from '@/filters/normalizeImg';
  import ShopPlayerMixin from '@/shared/mixins/shop-player.mixin';
  import { amsClient } from '@/service/ams';
  import LockIconComponent from '@/shared/components/lock-icon.vue';

  export default {
    name: 'BannerVideoItem',
    components: {
      LockIconComponent,
      BannerVideoItemInfo,
    },
    mixins: [ShopPlayerMixin],
    props: {
      item: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      link: {
        type: String,
        default: null,
      },
      currentIndex: {
        type: Number,
        required: true,
      },
      bannersSize: {
        type: String,
        default: 'large',
        require: true,
        validator(value) {
          return ['small', 'medium', 'large'].includes(value);
        },
      },
      hlsVideoService: {
        type: Object,
        require: true,
        default: null,
      },
    },
    data() {
      return {
        // if we want openVod from @touchend event, we need to add this flag for prevent automatic openVod play when we swipe video-banners
        // isVideoBannerSwiped: false,
        isVisible: false,
        isVertical: false,
        autoplayVideo: null,
        bannerInfo: null,

        visibilityOptions: {
          threshold: 1,
        },
      };
    },
    computed: {
      ...mapGetters({
        chachedPreviewMedia: 'chachedPreviewMedia/chachedPreviewMedia',
      }),
      bannerWithAutoplay() {
        return !!this.item?.autoplay_info;
      },
      showInfoBlock() {
        return this.bannerInfo && this.currentIndex === this.index && !this.bannerWithAutoplay;
      },
      infoBlockWidth() {
        if (this.bannersSize === 'small') {
          return '62%';
        }
        if (this.bannersSize === 'medium') {
          return '46%';
        }
        return '25%';
      },
      bannerPaddingTop() {
        if (this.bannersSize === 'small') {
          return '20%';
        }
        if (this.bannersSize === 'medium') {
          return '30%';
        }
        return '42%';
      },
      media() {
        return this.chachedPreviewMedia[this.item.b_id];
      },
      image() {
        return this.item?.banner_stb || this.item?.banner;
      },
      // observer() {
      //   return new IntersectionObserver(this.checkVisibility, this.visibilityOptions);
      // },
    },
    watch: {
      currentIndex: {
        immediate: true,
        handler(currentIndex) {
          this.isVertical = false;
          this.handleVideoPreview(currentIndex);
        },
      },
    },
    async mounted() {
      await this.fetchBannerInfo();
      // this.observer.observe(this.$refs[`video_${this.item.b_id}`]);
    },
    beforeDestroy() {
      // this.observer.unobserve(this.$refs[`video_${this.item.b_id}`]);
    },
    methods: {
      ...mapActions({
        addPreviewMediaItem: 'chachedPreviewMedia/addPreviewMediaItem',
      }),
      async fetchBannerInfo() {
        try {

          if (this.item.autoplay_info) {
            if (!this.item.autoplay_info?.autoplay_source) {
              return;
            }
            const link = `/get_item/${this.item.autoplay_info?.autoplay_source}`;
            const response = await amsClient.callAms(link, { cache: false });
            this.bannerInfo = response.head;
            return;
          }

          if (!this.item.link) {
            return;
          }
          const response = await amsClient.callAms(this.item.link, { cache: false });
          this.bannerInfo = response.head;
          // this.bannerInfo = {
          //   title: 'Test title',
          //   description: 'Test description',
          //   creator: {
          //     name: 'Creator Name',
          //   },
          // };
        } catch (error) {
          console.warn(error);
        }
      },
      normalizeImg: normalizeImg,
      async handleVideoPreview(currentIndex) {
        this.hlsVideoService.clear();
        if (!this.canPlay()) {
          return;
        }
        if (currentIndex === this.index && this.item.autoplay_info?.mid && this.getPlayTemplate?.info) {
          const link = this.getPlayTemplate?.info['vod']?.replaceAll('{MID}', this.item.autoplay_info?.mid);
          const isVertical = !!this.item.autoplay_info?.ap_media_is_vertical;
          console.log('banner autoplay mid link', link);
          this.addPreviewMediaItem({ id: this.item.b_id, link, isVertical });
        } else if (currentIndex === this.index && this.item.autoplay_info?.media_url && this.getPlayTemplate?.info) {
          const link = this.item.autoplay_info?.media_url;
          const isVertical = !!this.item.autoplay_info?.ap_media_is_vertical;
          console.log('banner autoplay media_url link', link);
          this.addPreviewMediaItem({ id: this.item.b_id, link, isVertical });
        } else if (currentIndex === this.index && this.item?.link) {
          if (this.item.itype === 'item_mov_vod_shoprz') {
            if (!this.media) {
              try {
                const playItem = await amsClient.callAms(`/play/${this.item.id}`, { cache: true });
                const isVertical = playItem.head.media_is_vertical;

                this.addPreviewMediaItem({ id: this.item.b_id, link: playItem.media[0].link, isVertical });
              } catch {
              }
            }
          } else if (this.item.itype === 'item_epg_shop_event') {
            this.addPreviewMediaItem({ id: this.item.b_id, link: this.item.media });
          }

          if (!this.media) {
            return;
          }
        } else {
          return;
        }
        return this.bindMediaPreview();
      },
      bindMediaPreview() {
        this.isVertical = this.media.isVertical; // based on WEB2-2044

        setTimeout(async () => {
          // Don't change it to the ref. It will not works with swiper loop.
          const video = document.querySelector(`#id${this.item.b_id}.swiper-slide-active video`);
          this.hlsVideoService.bindVideo(video, this.media.link);

          if (this.hlsVideoService.canPlay()) {
            const playing = await this.hlsVideoService.play(1);

            if (!playing) {
              return;
            }

            this.isVertical = this.media.isVertical;

            // Pause autoplay slider
            this.$emit('pauseAutoPlay');

            this.hlsVideoService.onVideoEnded(() => {
              // Run autoplay slider
              this.$emit('runAutoPlay');
            });
          }
        }, 100);
      },
      // checkVisibility([intersectionObj]) {
      //   this.isVisible = intersectionObj.isIntersecting;
      //   this.handleVideoPreview(this.currentIndex);
      // },
      async openVod() {

        let storeSlug = this.bannerInfo?.storefront?.slug || this.item?.storefront?.slug;
        if (this.item.itype === 'item_mov_vod_shoprz') {
          this.$stats.send('object_click', { position: this.index }, { item: this.item });

          this.hlsVideoService.clear();

          this.$router.push(`/s/${storeSlug}/v/${this.item.slug || this.item.id}`);
        } else if (this.link && this.item.itype !== 'item_epg_shop_event') {
          if(!storeSlug){
            const { head: storefront } = await amsClient.callAms(`/shoprzapi/storefront/${this.item.storefront}`);
            storeSlug = storefront?.slug;
          }

          const link = `/s/${storeSlug}${this.link}`;

          const { head: event } = await amsClient.callAms(link);

          if (event?.status === 'hidden' || event?.status === 'canceled') {
            return;
          }

          if (event?.status === 'waiting' && dayjs.utc(event?.end).isBefore(new Date())) {
            return;
          }
          return this.$router.push(link);
        } else if (this.item.itype === null && this.link) {
          let link = this.link.replace(/http:\/\/|https:\/\//g, '');
          link = `/${link.split('/').slice(1).join('/')}`;
          return this.$router.push(link);
        }
      },
      isShowLockIcon() {
        return this.item.itype === 'item_mov_vod_shoprz' && !amsClient.canPlay(this.item);
      },
      canPlay() {
        return this.item.itype !== 'item_mov_vod_shoprz' || amsClient.canPlay(this.item);
      },
    },
  };
</script>
<style lang="scss" scoped></style>