<template>
  <div class="pro-carousel-landscape-component">
    <carousel-slider
      :items="items"
      :row="row"
      :column="column"
      :infinite="true"
      :autoplay="false"
      :step="step"
      :breakpoints="breakpoints"
    >
      <!--v-on:update:componentId="changeCarouselId($event)"-->
      <template slot-scope="{item}">
        <main-realated-card
          :item="item"
          :active-play="currentPlay && currentPlay.id === item.id"
          @click.native="onClickItem($event, item)"
        />
      </template>
    </carousel-slider>
  </div>
</template>

<script>
  import CarouselSlider from 'src/components/carousel/carousel-slider';
  import MainRealatedCard from './main-related-component';
  import Config from 'src/service/config';


  export default {
    name: 'MainRelatedCarousel',
    components: {
      CarouselSlider,
      MainRealatedCard,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
      currentPlay: {
        type: Object,
        required: true,
      },
      eventIdentifier: {
        type: [String, Number],
      },
      autoplay: {
        type: Boolean,
      },

    },
    data() {
      return {
        // context: {},
        items: this.area.items || [],

        breakpoints: [{
                        width: 768,
                        column: 3,
                      },
                      {
                        width: 640,
                        column: 2,
                      },
        ],
      }
    },
    computed: {
      gridConfig() {
        return this.area.grid ? this.area.grid.split('_') : [1, 4, 4, 'manual'];
      },
      row() {
        return +this.gridConfig[0];
      },
      column() {
        return +this.gridConfig[1];
      },
      step () {
        return +this.gridConfig[2];

      },
    },
    mounted() {

    },
    methods: {
      getArrowsColor() {
        if (this.area.arrow_color) {
          return Config.get('colors') && Config.get('colors')[this.arrowColor] ? Config.get('colors')[this.arrowColor] : this.area.arrow_color;
        } else {
          return 'white';
        }
      },
      onClickItem(event, item) {
        let pos = this.area.items.findIndex((el) => {
          return el.id === item.id;
        });
        this.$bus.$emit(this.eventIdentifier + '_setCurrent', item);
        this.$bus.$emit(this.carouselId + '_clickNext', {
          pos: pos,
        });
        // event.stopPropagation();
        // event.preventDefault();
      },
      //      changeCarouselId(val) {
      //        // console.log('M', val);
      //        // console.log('M2', this.carouselId, val2);
      //        this.$emit('update:carouselId', val);
      //      }
    },
  }
</script>

<style lang="scss">
	@import './main-related-carousel';
</style>
