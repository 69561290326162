<template>
  <div class="tabs-segment" v-if="tabsInTabSegment.length">
    <div v-if="!isMobile" class="tabs">
      <div
        v-for="(tab, index) in tabsInTabSegment"
        :key="index"
        class="tab tooltip"
        :class="{
          active: tab.id === activeTabId,
          single: tabsInTabSegment.length === 1,
        }"
        @click="setActiveTab(tab)"
      >
        <span class="tooltiptext">{{ tab.title }}</span>
        <div class="sp-text-truncate">
          {{ tab.title }}
        </div>
      </div>
    </div>
    <div v-else>
      <swiper
        ref="swiper"
        :key="tabsInTabSegment.length"
        class="swiper tabs"
        :class="{
          'swiper-centered': isCenteredSwiper,
        }"
        :options="tabsSwiperConfig"
        :virtual="true"
        @slide-change="onTabSwipe"
      >
        <swiper-slide
          v-for="(tabSlide, i) in tabsInTabSegment"
          :key="tabSlide.id + i"
          :class="{ active: tabSlide.id === activeTabId }"
          class="tab-mobile"
          @click.native="setActiveTab(tabSlide)"
        >
          <!--          <slot name="item" :item="tabSlide" :index="i">-->
          <!--            -->
          <!--          </slot>-->
          <div class="sp-text-truncate">
            {{ tabSlide.title }}
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <!--    <transition name="slide">-->
    <tab-areas
      is-tabs-areas
      :key="activeTabId"
      :areas-transition="false"
      :areas="activeTabAreas"
      :page="'home'"
      is-tab
      :scroll="false"
    />
    <!--    </transition>-->
    <div v-show="loading" class="loader-container">
      <loader color="#798B96" />
    </div>
  </div>
</template>
<script>
  import { amsClient } from '@/service/ams';
  import Loader from '@/components/loader/loader';
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
  import {
    DEFAULT_CAROUSEL_CONFIG,
    TABS_CAROUSEL_CONFIG,
  } from '@/config/carousel.config';

  export default {
    name: 'SegmentTabs',
    components: {
      Swiper,
      SwiperSlide,
      Loader,
      // Should be dynamic import for recursive component use in th Shop Tabs
      TabAreas: () => import('@/components/areas/areas'),
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    data: function () {
      return {
        tabsInTabSegment: [],
        activeTab: {},
        // areas: [],
        loading: false,
      };
    },
    computed: {
      activeTabId() {
        return this.activeTab?.id || 0;
      },
      activeTabAreas() {
        return this.activeTab?.areas || [];
      },
      tabsSwiperConfig() {
        const nTabs = this.tabsInTabSegment.length;
        let slides = 1;
        if (this.tabsInTabSegment.length < 4) {
          slides = this.tabsInTabSegment.length;
        }
        // const nSlides = Math.floor(window.innerWidth / 150);
        // slides = nSlides >= nTabs ? nTabs : +`${nSlides}.25`;
        slides = +`${Math.floor(window.innerWidth / 150)}.25`;
        return {
          ...TABS_CAROUSEL_CONFIG,
          slidesPerView: slides,
        };
      },
      isCenteredSwiper() {
        const nTabs = this.tabsInTabSegment.length;
        const nSlides = Math.floor(window.innerWidth / 150);
        return nSlides >= nTabs;
      },
      isMobile() {
        return window.innerWidth <= 990;
      },
    },
    watch: {},
    mounted() {
      this.getSegmentTabs();
    },
    beforeDestroy() {},
    methods: {
      setActiveTab(tab) {
        this.activeTab = tab;
        // this.areas = tab.areas;
        // console.log('tab.areas', tab.areas);
      },
      getSegmentTabs() {
        this.loading = true;
        let link = this.area.link;

        amsClient
          .callAms(link)
          .then(async (data) => {
            console.log('data', data);
            this.loading = false;
            this.tabsInTabSegment = data.items;
            // this.tabsInTabSegment = [
            //   {
            //     "title": "Tab 1 Long Long Long Long",
            //     "link": "/screen/tab1/",
            //     "id": "64c7bc7bce1b05009b715784",
            //     "itype": "screen_shoprz",
            //   },
            //   {
            //     "title": "Tab 2 Long Long Long Long",
            //     "link": "/screen/tab2/",
            //     "id": "64c904f9d293e49a8ff61bee",
            //     "itype": "screen_shoprz",
            //   },
            //   {
            //     "title": "Tab 3 Long Long Long Long",
            //     "link": "/screen/tab2/",
            //     "id": "sadasdas",
            //     "itype": "screen_shoprz",
            //   },
            //   {
            //     "title": "Tab 4",
            //     "link": "/screen/tab2/",
            //     "id": "asd",
            //     "itype": "screen_shoprz",
            //   },
            //   {
            //     "title": "Tab 5",
            //     "link": "/screen/tab1/",
            //     "id": "asdsa",
            //     "itype": "screen_shoprz",
            //   },
            //   // {
            //   //   "title": "Tab 6",
            //   //   "link": "/screen/tab2/",
            //   //   "id": "asdas",
            //   //   "itype": "screen_shoprz",
            //   // },
            //   // {
            //   //   "title": "Tab 7",
            //   //   "link": "/screen/tab2/",
            //   //   "id": "12321dasd",
            //   //   "itype": "screen_shoprz",
            //   // },
            //   // {
            //   //   "title": "Tab 8",
            //   //   "link": "/screen/tab2/",
            //   //   "id": "asdasd",
            //   //   "itype": "screen_shoprz",
            //   // },
            // ]

            // take default first tab
            this.activeTab = this.tabsInTabSegment[0];
            this.loading = true;
            const { items: areas } = await amsClient.callAms(
              this.activeTab.link,
            );
            this.activeTab = {
              ...this.activeTab,
              areas,
            };
            // console.log('areas', areas);

            // ToDo because we don't have triggers about finishing of segments loading. need to add that logic
            setTimeout(() => (this.loading = false), 1500);

            // Load other tabs items in background
            /* ToDo: probably its not a good way for performance, but load it dynamically on activation
              ToDO: will also leads to a lot of problems with loading and animation. And also we don't have appropriate triggers
              ToDo: for success items loading inside segments */
            const promises = this.tabsInTabSegment.map(async (tab) => {
              const { items: areas } = await amsClient.callAms(tab.link);
              tab.areas = areas;
            });
            await Promise.all(promises);
          })
          .catch(() => {
            // this.areas = [];
            this.loading = false;
          });
      },
      onTabSwipe() {},
    },
  };
</script>

<style lang="scss">
  .tabs.swiper-centered {
    .swiper-wrapper {
      justify-content: center;
    }
  }
  // WEB2-2643
  .with-player .tabs-segment .ugc-virtual-scroll {
    .segment-header {
      margin-top: 100px;
    }
  }
  // .tabs-segment .ugc-virtual-scroll {

  //   .segment {
  //     padding-top: 0;
  //   }
  // }
</style>
<style lang="scss" scoped>
  // WEB2-2643
  .tabs-segment {
    min-height: 358px;
  }

  /* Tooltip Container */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip Text */
  .tooltip .tooltiptext {
    visibility: hidden;
    //display: flex;
    max-width: 200px;
    width: max-content;
    min-width: 100px;
    //white-space: nowrap;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the element */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip Arrow */
  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%; /* Position arrow above the tooltip text */
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  /* Show Tooltip on Hover */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 0.8;
  }

  .tabs {
    display: flex;
    //overflow: hidden;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .tab {
    max-width: 165px;
    min-width: 120px;
    padding: 0 16px;
    //overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    //padding: 5px 10px;
    cursor: pointer;
    flex: 1;
    text-align: center;
    border: 1px solid #e2e6ee;
    border-right: none;
    border-radius: 0;
  }

  .tab-mobile {
    //max-width: 140px;
    //min-width: 105px;
    padding: 0 16px;
    height: 40px;
    //overflow: hidden;
    //text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #e2e6ee;
    border-right: none;
    border-radius: 0;
    white-space: nowrap;
  }

  .sp-text-truncate {
    //width: 120px;
    //display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tab:first-child,
  .tab-mobile:first-child {
    border-radius: 5px 0 0 5px;
  }

  .tab:last-child,
  .tab-mobile:last-child {
    border-right: 1px solid #e2e6ee;
    border-radius: 0 5px 5px 0;
  }

  .tab.active,
  .tab-mobile.active {
    background-color: #343434;
    color: white;
  }

  .tab.single {
    border-radius: 5px !important;
  }

  .slide-leave-active,
  .slide-enter-active {
    transition: 1s;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
</style>
