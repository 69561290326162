<template src="./auto-segment-carousel.html"/>
<script>
  import { FollowMixin } from 'src/plugins/mixin';
  import contentLanguage from 'src/filters/contentLanguage';
  import { amsClient } from 'src/service/ams';
  import SwiperCarousel from 'src/components/swiper-carousel/swiper-carousel';

  import {
    DEFAULT_CAROUSEL_CONFIG,
    CIRCLE_CAROUSEL_ITEMS_CONFIG,
    VERTICAL_CAROUSEL_ITEMS_CONFIG,
  } from 'src/config/carousel.config';

  export default {
    name: 'AutoSegmentCarousel',
    components: {
      SwiperCarousel,
    },
    mixins: [FollowMixin],
    props: {
      area: {
        type: Object,
        required: true,
      },
      storefrontId: {
        type: String,
        default: '',
      },
    },
    data: function () {
      return {
        loading: false,
        segmentItems: [],
        subscribers: [],
      };
    },
    computed: {
      segmentItemsNormalized() {
        return this.segmentItems.map((item) => {
          item.title = item.title || item.name
          item.itype = this.sectionType
          return item;
        })
      },
      isUpcomingSegment() {
        return this.area.name === 'coming_soon';
      },
      isUploadsSegment() {
        return this.area.name === 'new_videos';
      },
      isPopularVideosSegment() {
        return this.area.name === 'popular_videos';
      },
      isOnAirSegment() {
        return this.area.name === 'on_air';
      },
      isChannelsSegment() {
        return this.area.name === 'store_channels';
      },
      isDepartmentsSegment() {
        return this.area.name === 'departments';
      },
      isProductsSegment() {
        return this.area.name === 'new_products';
      },
      isRecentProductsSegment() {
        return this.area.name === 'shop_recent';
      },
      isRecentlyWatchedSegment() {
        return this.area.name === 'recent';
      },
      segmentType() {
        if (this.isChannelsSegment) {
          return 'circle';
        }
        if (this.isProductsSegment || this.isRecentProductsSegment) {
          return 'vertical';
        }
        return 'horizontal'
      },
      title() {
        return contentLanguage(this.area, 'title');
      },
      carouselConfig() {
        if (this.isChannelsSegment || this.isProductsSegment || this.isRecentProductsSegment) {
          return VERTICAL_CAROUSEL_ITEMS_CONFIG;
        }

        return DEFAULT_CAROUSEL_CONFIG;
      },
      viewAllUrl() {
        if (this.isDepartmentsSegment) {
          return `/departments?t=${this.title}`
        }
        if (this.isChannelsSegment) {
          return `/channels?t=${this.title}`
        }
        if (this.isUpcomingSegment) {
          return `/upcoming?t=${this.title}` + (this.storefrontId && `&tarId=${this.storefrontId}`)
        }
        if (this.isUploadsSegment) {
          return `/uploads?t=${this.title}` + (this.storefrontId && `&tarId=${this.storefrontId}`)
        }
        if (this.isPopularVideosSegment) {
          return `/popular-videos?t=${this.title}` + (this.storefrontId && `&tarId=${this.storefrontId}`)
        }
        if (this.isOnAirSegment) {
          return `/onair?t=${this.title}` + (this.storefrontId && `&tarId=${this.storefrontId}`)
        }
        if (this.isRecentlyWatchedSegment) {
          return `/recently-watched-videos?t=${this.title}` + (this.storefrontId && `&tarId=${this.storefrontId}`)
        }
        if (this.isPopularVideosSegment) {
          return `/popular-videos?t=${this.title}` + (this.storefrontId && `&tarId=${this.storefrontId}`)
        }
        if (this.isRecentProductsSegment) {
          return `/recently-watched-products?t=${this.title}`
        }
        if (this.isProductsSegment) {
          return `/new-arrivals?t=${this.title}`
        }
        return '';
      },
      sectionType() {
        if (this.isRecentProductsSegment || this.isProductsSegment) {
          return 'shop_product'
        }
        if (
          this.isUploadsSegment
          || this.isPopularVideosSegment
          || this.isRecentlyWatchedSegment
        ) {
          return 'item_mov_vod_shoprz'
        }
        if (this.isOnAirSegment || this.isUpcomingSegment) {
          return 'item_epg_shop_event'
        }
        return 'screen_shoprz_page'
      },
    },
    mounted() {
      this.getSegmentItems();
    },
    beforeDestroy() {
      // this.$bus.$off('changedUserState', this.getSegmentItems);
      // rtm.off(this.subscribers);
    },
    methods: {
      getSegmentItems() {
        let link = this.area?.link
        // todo remove when backend will properly fix filter
        // if (this.isProductsSegment) {
        //   link = link.replace(/@target_id:[a-zA-Z0-9]+/, '');
        // }
        amsClient
          .callAms(link)
          .then((data) => {
            this.loading = false;

            this.segmentItems = data.items;
          })
          .catch(() => {
            this.loading = false;
            this.segmentItems = [];
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  $segment-border-color: #e5e8f1;

  .segment {
    max-width: 100%;
    margin: 0 auto 10px;
    padding: 25px 2.5rem;
    //overflow: hidden;
    background: #e3e3e9;

    .carousel {
      margin-bottom: 0;
      min-height: 0;
    }

    @media (max-width: 420px) {
      padding-right: 20px;
      padding-left: 20px;
    }

    @media screen and (min-width: 1600px) {
      padding: 25px 2.5rem;
    }

    //&.with-border::after {
    //  content: '';
    //  display: block;
    //  height: 1px;
    //  margin-top: 30px;
    //  background-color: $segment-border-color;
    //}
  }

  .segment-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .segment-title {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    //color: $main-text-color !important;
  }

  .segment-link {
    font-weight: 500;
    font-size: 14px;
    color: $main-color;

    &:hover {
      opacity: 0.6;
    }
  }
</style>
