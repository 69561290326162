<template>
  <section class="banner">
    <swiper
      v-if="carouselItems.length > 0"
      ref="mySwiper"
      :options="swiperOptions"
      @slideChangeTransitionEnd="onSlideChange"
    >
      <swiper-slide :id="'id' + slide.b_id" v-for="(slide, index) of carouselItems" :key="`${slide.b_id}_${index}`">
        <component
          :is="getComponentType(slide)"
          :hls-video-service="hlsVideoService"
          :link="normalizedItemLink(slide)"
          :item="slide"
          :index="index"
          :current-index="currentIndex"
          :banners-size="bannersSize"
          @click.native="handleClickSlide(slide)"
          @runAutoPlay="toggleAutoPlay('run')"
          @pauseAutoPlay="toggleAutoPlay('pause')"
        >
          <template slot="title">
            <div v-if="slide.title || slide.subtitle" class="banner-names">
              <h2 v-if="slide.title" class="banner-names__title">
                {{ slide | contentLanguage('title') }}
              </h2>
              <p v-if="slide.subtitle" class="banner-names__subtitle">
                {{ slide | contentLanguage('subtitle') }}
              </p>
            </div>
          </template>
        </component>
      </swiper-slide>
    </swiper>
    <ul class="banner-pagination">
      <li
        v-for="(item, index) of carouselItems"
        :key="item.iid"
        :class="{ 'active': index === currentIndex }"
        @click="onPaginationClick(index)"
      />
    </ul>
  </section>
</template>
<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import Config from '@/service/config';
  import { linkNormalisation } from '@/filters';

  import BannerLinkItem from './banner-link-item/banner-link-item';
  import BannerProductItem from './banner-product-item/banner-product-item';
  import BannerVideoItem from './banner-video-item/banner-video-item';
  import { amsClient } from '@/service/ams';
  import HlsVideoService from '@/service/hls-video';

  export default {
    name: 'BannerCarousel',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        currentIndex: 0,
        carouselItems: [],
        hlsVideoService: null,
      };
    },
    computed: {
      swiperOptions() {
        const delay = Config.get('banners_call_gap');
        return {
          loop: true,
          centeredSlides: true,
          spaceBetween: 20,
          slidesPerView: 1,
          autoplay: {
            disableOnInteraction: false,
            delay,
          },
          breakpoints: {
            1025: {
              slidesPerView: 2,
            },
          },
        };
      },
      store() {
        return amsClient.get('conf.store');
      },
      swiper() {
        return this.$refs.mySwiper?.$swiper;
      },
      // carouselItems() {
      //   return this.area.items || [];
      // },
      bannersSize() {
        return this.area?.banner_size || 'large';
      },
    },
    mounted() {
      // Should be separate for each banner-carousel component
      this.hlsVideoService = new HlsVideoService();
      this.getSegmentItems();
    },
    methods: {
      async getSegmentItems() {
        const link = this.area.link;
        const { items } = await amsClient.callAms(link);
        this.carouselItems = items;
      },
      onPaginationClick(index) {
        this.swiper?.slideTo(index + 2, 400);
      },
      onSlideChange() {
        this.currentIndex = this.swiper?.realIndex;

        // don't remove that hack. For issue with swiper loop. https://github.com/surmon-china/vue-awesome-swiper/issues/226
        if (this.swiper?.realIndex === this.carouselItems.length - 1) {
          this.swiper?.slideToLoop(this.carouselItems.length - 1, 0);
        }
        // don't remove that hack. For issue with swiper loop. https://github.com/surmon-china/vue-awesome-swiper/issues/226
        if (this.swiper?.realIndex === 0) {
          this.swiper?.slideToLoop(0, 0);
        }
      },
      getComponentType(item) {
        if ((item.autoplay_info?.mid || item.autoplay_info?.media_url)) {
          return BannerVideoItem;
        }

        if (item.itype === 'item_mov_vod_shoprz') {
          return BannerVideoItem;
        }

        if (item.itype === 'shop_product') {
          return BannerProductItem;
        }

        return BannerLinkItem;
      },
      // getAutoplayBannerLink(item) {
      //   if (item.autoplay_info?.mid || item.autoplay_info?.media_url) {
      //     if (item?.itype === 'screen_shoprz_page') {
      //       return '/' + this.item?.link;
      //     }
      //     if (item.itype === 'item_epg_shop_event' && item?.link) {
      //       return `/s/${this.store.name.toLowerCase()}${item?.link}`
      //     }
      //     return item?.link;
      //   }
      //   return null;
      // },
      normalizedItemLink(item) {
        if (!item?.link) {
          return null;
        }

        // ToDO current backend links incorrect for channels and departments
        if (item.itype === 'screen_shoprz_page') {
          return '/' + item?.link;
        }
        // alert(item.link)

        // if (item.itype === 'item_epg_shop_event' && item?.link) {
        //   return `/s/${this.store.name.toLowerCase()}${item?.link}`
        // }

        // if (item.itype === 'shop_product') {
        //   const backSlug = item.link.split('/').reverse()[0];
        //   const slug = item.slug || backSlug;
        //   return slug ? `/product/${slug}` : null;
        // }

        return item?.link;
      },
      handleClickSlide(item) {
        const id = item.b_id || item.id;
        let eventData = { banner_id: id };

        Config.set('temp.context', 'banner');
        // this.$stats.send(
        //   'banner_click',
        //   Object.assign({ item_id: id, banner_target: linkNormalisation(item) }, eventData),
        // );
        // assume that it is link to external site
        if (item.link && item.link.indexOf('http') !== -1) {
          // this.$stats.send(
          //   'external_application_opened',
          //   { ext_app_name: item.link },
          // );
        }
      },
      toggleAutoPlay(mode) {
        if (mode === 'pause') {
          this.swiper.autoplay.pause();
        }
        if (mode === 'run') {
          this.swiper.slideNext();
          this.swiper.autoplay.run();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .banner {
    position: relative;
    padding-top: 8px;
    padding-bottom: 13px;

    .swiper-slide {
      border-radius: 10px;
      overflow: hidden;
    }

    @media (max-width: 1024px) {
      padding-top: 0;
      padding-bottom: 13px;
    }

    &::v-deep {
      .banner-item {
        position: relative;
        display: block;
        background-color: $header-background-color;
        cursor: pointer;
      }

      .banner-preview {
        position: relative;
        //padding-top: 50%;

        img,
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: fill;
          //background-position: center;
          background-size: cover;

          &.is-vertical, &.is-medium-banner, &.is-small-banner, &.is-large-banner {
            object-fit: contain;
            object-position: left;
            //background-image: none !important;
            //background-color: #000;
            &[src^="blob:"], &[src^="https:"] {
              background-image: none !important;
              background-color: #000;
            }

            &:has(> source) {
              background-image: none !important;
              background-color: #000;
            }
          }

          &.is-large-banner.is-vertical.with-banner-info {
            object-position: 34%;
          }

          &.is-medium-banner.is-vertical.with-banner-info {
            object-position: 24%;
          }

          &.is-small-banner.is-vertical.with-banner-info {
            object-position: 14%;
          }

          &:not(.with-banner-info) {
            object-position: center;
          }

          &.is-large-banner:not(.with-banner-info) {
            background-color: #000;
            object-position: center !important;
            object-fit: contain;

            &[src^="blob:"], &[src^="https:"] {
              background-image: none !important;
              background-color: #000;
            }

            &:has(> source) {
              background-image: none !important;
              background-color: #000;
            }
          }
        }

        video {
          // ToDO Commented based on WEB2-1818
          width: 100%;
          display: flex;
          //width: calc(100% - 26%);

          @media (max-width: 680px) {
            width: 100%;
          }
        }
      }

      .banner-names {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 50px;
        padding: 24px;
        left: 0;
        right: 0;
        bottom: 0;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          background: linear-gradient(to bottom, transparent 0%, $header-background-color 92%);
        }

        &__title {
          position: relative;
          display: block;
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          z-index: 1;
        }

        &__subtitle {
          @extend .banner-names__title;
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }

  .banner-pagination {
    position: absolute;
    bottom: -13px;
    left: 50%;
    // display: flex;
    display: none;
    gap: 8px;
    margin: 0;
    padding: 0;
    transform: translateX(-50%);

    @media (max-width: 1024px) {
      bottom: 55px;
      z-index: 2;
    }

    li {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #e2e5f1;
      cursor: pointer;

      &.active {
        background-color: $main-color;
      }
    }
  }
</style>