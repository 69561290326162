<template>
  <a
    class="main-related-component"
    ondragstart="return false;"
    draggable="false"
  >
    <!-- <div class="main-related-component-thumb main-related-component-thumb-img" v-bg-image-js="{name: poster, type: 'thumb'}"> -->
    <!-- <img :src="poster | normalizeImg('thumb')" class="main-related-component-thumb-img" :style="{height: heightImg}" /> -->
    <!-- <div class="main-related-component-thumb-img" ></div> -->
    <div class="main-related-component-thumb embed-responsive embed-responsive-16by9">
      <img
        class="main-related-component-img embed-responsive-item"
        :src="item.poster| normalizeImg('thumb')"
        :alt="item.title"
        :title="item.title"
      >
      <!-- <v-lazy-image :intersection-options="{'rootMargin': '0px 270px' }" class="main-related-component-img embed-responsive-item" :src="item.poster| normalizeImg('thumb')" :alt="item.title" :title="item.title" /> -->
      
      <div
        class="overlay"
        :class="{ 'main-related-component-transparent-overlay' : !activePlay}"
      />
      <duration
        class="pro-duration"
        :class="{ 'active-play': activePlay}"
        :duration="duration"
      />
    </div>
    <!-- <h5 class="main-related-component-title" :title="title" v-ellipsisText="{content: title, resize: true}" :class="{'main-related-component-title-active': activePlay}"> </h5> -->
    <trim-el
      class="main-related-component-title"
      :text="title"
      tag="h5"
    />
  </a>
</template>

<script>
  import duration from '../duration/duration';
  import TrimEl from 'src/components/common/trim-el';

  export default {
    name: 'MainRelated',
    components: {
      duration,
      TrimEl,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      activePlay: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        heightImg: 'auto', // for all components ???,
        minHeightComponent: '190px',

      }
    },
    computed: {
      poster() {
        return this.item.poster || this.item.backdrop;
      },
      duration() {
        return this.item.duration;
      },
      title() {
        return this.item.title;
      },
    },
    mounted() {
      // this.setHeight16x9();
      // this.$bus.$on('resizeWindow', this.handleSize);

    },
    beforeDestroy() {
      // this.$bus.$off('resizeWindow', this.handleSize);
    },
    methods: {
      setHeight16x9() {
        let ht = (this.$el) ? this.$el.offsetWidth / 16 * 9 : null;
        // console.dir(this.$el);
        // console.log(this.$el.offsetWidth, ht);

        this.heightImg = (ht) ? ht + 'px' : 'auto';
        this.minHeightComponent = (ht) ? ht + 70 + 'px' : 'auto';
      },
      handleSize() {
        this.setHeight16x9();
      },
    },

  }
</script>

<style lang="scss">
	@import './main-related-component';
</style>
